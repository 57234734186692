import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { IndexHeader } from "../../components/header"

const CategoriesPage = ({ data }) => {
  const {
    allMarkdownRemark: { group },
  } = data

  return (
    <Layout>
      <SEO
        title="Categories"
        description="Frontend and backend engineering topics"
        path="/blog/categories"
        keywords={group.map(category => category.fieldValue)}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <IndexHeader breadcrumbs={["blog"]}>Categories</IndexHeader>
        <ul className="divide-gray-300 divide-y">
          {group.map(category => (
            <li key={category.fieldValue}>
              <Link
                to={`/blog/categories/${kebabCase(category.fieldValue)}/`}
                className="text-primary-400 hover:text-primary-500"
              >
                <div className="flex items-center space-x-4">
                  <h3 className="font-black text-3xl text-primary-500 capitalize">
                    {category.fieldValue}
                  </h3>
                  <span className="bg-gray-200 bg-opacity-50 font-black px-3 py-1 rounded-full text-orange-400">
                    {category.totalCount}
                  </span>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

CategoriesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default CategoriesPage

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { draft: { eq: false } }
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`
